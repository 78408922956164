import React from "react";
import { AmidaEvent } from "../../../domain/types/Event";
import {
  ButtonBase,
  Card,
  CardContent,
  List,
  ListItem,
  Paper,
  Typography,
  Box as MuiBox,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

type eventListProps = {
  adminEvents: AmidaEvent[];
  joinedEvents: AmidaEvent[];
};

const EventList = (props: eventListProps) => {
  const navigate = useNavigate();

  const getEventStatusLabel = (event: AmidaEvent) => {
    if (event.status === "open") {
      return "作成済み";
    } else if (event.status === "waiting") {
      return "参加受付中";
    } else if (event.status === "closed") {
      return "完了";
    } else {
      return "不明";
    }
  };
  return (
    <>
      <Paper>
        <Typography variant="h1">あみだくじ（管理）</Typography>
        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
          {props.adminEvents.map((event) => (
            <ListItem key={event.id}>
              <ButtonBase
                sx={{ width: "100%", textAlign: "left" }}
                onClick={() => {
                  navigate("/e/" + event.id);
                }}
              >
                <Card
                  sx={{
                    width: "100%",
                    bgcolor: "background.paper",
                    boxShadow: 3,
                  }}
                >
                  <CardContent>
                    <Typography variant="body1">{event.title?.val}</Typography>
                    <Typography variant="body2" color="textSecondary">
                      {getEventStatusLabel(event)},　作成:
                      {event.createdAt
                        ? new Date(event.createdAt).toLocaleString()
                        : null}
                      ,　更新:
                      {event.updatedAt
                        ? new Date(event.updatedAt).toLocaleString()
                        : null}
                    </Typography>
                  </CardContent>
                </Card>
              </ButtonBase>
            </ListItem>
          ))}
        </List>
      </Paper>
      <MuiBox component="div" m={1} />

      <Paper>
        <Typography variant="h1">あみだくじ（参加）</Typography>
        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
          {props.joinedEvents.map((event) => (
            <ListItem key={event.id}>
              <ButtonBase
                sx={{ width: "100%", textAlign: "left" }}
                onClick={() => {
                  navigate("/e/" + event.id);
                }}
              >
                <Card
                  sx={{
                    width: "100%",
                    bgcolor: "background.paper",
                    boxShadow: 3,
                  }}
                >
                  <CardContent>
                    <Typography variant="body1">{event.title?.val}</Typography>
                    <Typography variant="body2" color="textSecondary">
                      {getEventStatusLabel(event)},　作成:
                      {event.createdAt
                        ? new Date(event.createdAt).toLocaleString()
                        : null}
                      ,　更新:
                      {event.updatedAt
                        ? new Date(event.updatedAt).toLocaleString()
                        : null}
                    </Typography>
                  </CardContent>
                </Card>
              </ButtonBase>
            </ListItem>
          ))}
        </List>
      </Paper>
    </>
  );
};

export default EventList;
