import { Button, Typography, Box as MuiBox } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

type JsonData = {
  title: string;
  sections: {
    title: string;
    content: string;
    list?: string[];
  }[];
};

type Props = {
  jsonFilePath: string;
};

const JsonContent = (props: Props) => {
  const { jsonFilePath } = props;

  const [jsonData, setJsonData] = useState<JsonData | null>(null);
  const navigate = useNavigate();

  React.useEffect(() => {
    fetch(`${jsonFilePath}`)
      .then((response) => response.json())
      .then((data) => setJsonData(data))
      .catch((error) => console.error("Error loading JSON:", error));
  }, [jsonFilePath]);

  const { title, sections } = jsonData || { title: "", sections: [] };

  return (
    <>
      <MuiBox
        component="div"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h1">{title}</Typography>
      </MuiBox>

      {sections.map((section, index) => (
        <React.Fragment key={index}>
          <Typography variant="body1" gutterBottom>
            {section.title}
          </Typography>
          <Typography variant="body2" gutterBottom>
            {section.content}
          </Typography>
          {section.list && (
            <Typography variant="body2" component="ul">
              {section.list.map((item, idx) =>
                item.includes("http") ? (
                  <li key={idx}>
                    <a
                      key={idx}
                      href={item}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item}
                    </a>
                  </li>
                ) : (
                  <li key={idx}>{item}</li>
                )
              )}
            </Typography>
          )}
        </React.Fragment>
      ))}

      <MuiBox m={2} component="div" />

      <Button
        variant="contained"
        color="secondary"
        fullWidth
        onClick={() => navigate("/")}
      >
        ホームに移動する
      </Button>
    </>
  );
};

export default JsonContent;
