import { createTheme } from '@mui/material/styles';

const lightTheme = createTheme({
    palette: {
        primary: {
            main: '#ffad80', // マイルドなオレンジ
        },
        secondary: {
            main: '#009999', // ティールブルー
        },
        background: {
            default: '#f5f5f5', // 背景色を少し落ち着かせたグレー
            paper: '#ffffff', // コンポーネント用の背景色を白に
        },
        text: {
            primary: '#333333', // テキスト色を暗めのグレーに
            secondary: '#666666', // サブテキスト用のグレー
        },
        action: {
            active: '#ffad80', // アクティブな要素にマイルドなオレンジ
            hover: '#ffd1a3', // ホバー時の柔らかいオレンジ
        },
        warning: {
            main: '#b0b0b0', // グレー系のマイルドな色
        },
    },
    typography: {
        fontSize: 14,
        fontFamily: [
            'Roboto', // Roboto フォント
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        button: {
            textTransform: 'none',
            fontWeight: 600,
        },
        h1: {
            fontSize: '1.2rem',
            fontWeight: 600,
        },
        h2: {
            fontSize: '1rem',
        },
        h3: {
            fontSize: '0.9rem',
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    backgroundColor: '#f5f5f5', // 背景色を少し落ち着かせたグレー
                    color: '#333333', // テキスト色を暗めのグレーに
                    '& a': {
                        color: '#ffad80', // リンクの色
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    color: '#f5f5f5',
                    borderRadius: '12px',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.15)', // 柔らかく自然な影
                    '&:hover': {
                        boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.2)', // ホバー時に少し強めの影
                    },
                    height: '45px',
                },
                //outlined のとき、影を少し弱める
                outlined: {
                    color: '#333333',
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', // 軽い影
                    '&:hover': {
                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.15)', // ホバー時に少し強めの影
                    },
                },
                containedPrimary: {
                    color: '#4d4d4d',
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#ffffff', // ヘッダーの背景色を白に
                    color: '#333333', // ヘッダーのテキスト色を暗めのグレーに
                    borderRadius: '12px',
                    marginBottom: '16px',
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', // 軽い影
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: '#ffffff', // ペーパーの背景色を白に
                    color: '#333333', // テキスト色を暗めのグレーに
                    borderRadius: '8px',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.15)', // 柔らかく自然な影
                    padding: '16px 12px', // ペーパーコンポーネントのパディングを少し小さくする
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    color: '#333333', // テキスト色を暗めのグレーに
                    margin: '8px 0',
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiInputBase-root': {
                        backgroundColor: '#f9f9f9', // 入力フィールドの背景色を少し明るいグレーに
                        color: '#333333', // 入力フィールドのテキスト色を暗めのグレーに
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#ffad80', // 枠線の色をオレンジに
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#ffd1a3', // ホバー時の枠線の色を柔らかいオレンジに
                    },
                    '& .MuiInputLabel-root': {
                        color: '#666666', // ラベルの色をグレーに
                    },
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    backgroundColor: '#ffffff', // ダイアログの背景色を白に
                    borderRadius: '12px',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.15)', // 柔らかく自然な影
                    padding: '16px',
                    minWidth: '320px',
                },
            },
        },
        MuiDialogContentText: {
            styleOverrides: {
                root: {
                    color: '#333333', // ダイアログコンテンツのテキスト色を暗めのグレーに
                    fontSize: '0.9rem',
                },
            },
        },
    },
});

export default lightTheme;
