import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { styled } from "@mui/system";

const TmpLabelContainer = styled(Box)({
  position: "relative",
});

const Label = styled(Typography)({
  position: "absolute",
  top: 20,
  right: -10,
  backgroundColor: "#FF69B4",
  color: "white",
  padding: "5px 10px",
  fontWeight: "bold",
  transform: "rotate(20deg)",
  transformOrigin: "top right",
});

type Props = {
  text: string;
};

const PageLabel = (props: Props) => {
  const { text } = props;
  const theme = useTheme();
  return (
    <TmpLabelContainer>
      <Label
        variant="body2"
        fontSize={13}
        style={{ backgroundColor: theme.palette.warning.main }}
      >
        {text}
      </Label>
    </TmpLabelContainer>
  );
};

export default PageLabel;
