import { useState } from "react";
import { useDispatch } from "react-redux";
import { User } from "../../domain/types/User";
import { useNavigate } from "react-router-dom";
import { createUser } from "../../domain/features/UserSlice";
import { createEvent } from "../../domain/features/EventSlice";
import { AmidaEvent } from "../../domain/types/Event";

const useHomePageBody = (user: User) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [eventTitle, setEventTitle] = useState("");
  // const [eventTitleError, setEventTitleError] = useState(false);

  const [bridgeNumRangeMin, setBridgeNumRangeMin] = useState(2);
  const [bridgeNumRangeMax, setBridgeNumRangeMax] = useState(2);

  const [playerLabelWithErrors, setPlayerLabelWithErrors] = useState<
    {
      label: string;
      error: boolean;
    }[]
  >([
    {
      label: "",
      error: false,
    },
    {
      label: "",
      error: false,
    },
  ]);

  const [playerOthersLabelWithError, setPlayerOthersLabelWithError] = useState<{
    label: string;
    error: boolean;
  }>({
    label: "",
    error: false,
  });

  // const [maxJoinedUsersNum, setMaxJoinedUsersNum] = useState<number>(499);
  const maxJoinedUsersNum = 499;

  const handleCreateEvent = async () => {
    if (!eventTitle) {
      alert("タイトルを入力してください");
      return;
    }

    if (eventTitle.length > 40) {
      alert("タイトルは40文字以内で入力してください");
      return;
    }

    // playerLabelsの文字列が長すぎる場合はエラー
    const hasError = playerLabelWithErrors.some(
      (playerLabelWithError) => playerLabelWithError.error
    );
    if (hasError) {
      alert("振り分け名を短くしてください");
      return;
    }

    // playerOthersLabelの文字列が長すぎる場合はエラー
    if (playerOthersLabelWithError.error) {
      alert("その他の振り分け名を短くしてください");
      return;
    }

    if (maxJoinedUsersNum < 2) {
      alert("最大参加人数は2人以上にしてください");
      return;
    }
    if (maxJoinedUsersNum > 499) {
      alert("最大参加人数は499人以下にしてください");
      return;
    }

    try {
      var userId = user.id;
      if (!userId) {
        const no_name = "ななしさん";

        const res = await dispatch(createUser(no_name) as any);
        const resUser: User = res.payload.user;
        if (!resUser.id) {
          throw new Error();
        }
        userId = resUser.id;
      }

      const resGetEvent = await dispatch(
        createEvent({
          title: eventTitle,
          adminUserId: userId,
          bridgeNumRangeMin: bridgeNumRangeMin,
          bridgeNumRangeMax: bridgeNumRangeMax,
          playerLabels: playerLabelWithErrors
            .map((playerLabelWithError) => playerLabelWithError.label)
            .filter((label) => label !== ""),
          playerOthersLabel:  playerOthersLabelWithError.label,
          maxJoinedUsersNum: maxJoinedUsersNum,
          isBlockEndWithoutAdmin: false,
          isBlockUpdateWithoutAdmin: false,
        }) as any
      );
      const resEvent: AmidaEvent = resGetEvent.payload.event;
      if (!resEvent.id) {
        throw new Error();
      }

      navigate("/create_complete?event_id=" + resEvent.id);
      // 作成完了ページを表示せずに、直接イベントページに遷移する場合は以下のコメントアウトを解除する
      // navigate("/e/" + resEvent.id);
    } catch (error: any) {
      alert("あみだくじ作成に失敗しました");
    }
  };

  return {
    createEvent: handleCreateEvent,
    eventTitle,
    setEventTitle,
    bridgeNumRangeMin,
    setBridgeNumRangeMin,
    bridgeNumRangeMax,
    setBridgeNumRangeMax,
    playerLabelWithErrors,
    setPlayerLabelWithErrors,
    playerOthersLabelWithError,
    setPlayerOthersLabelWithError,

    // maxJoinedUsersNum,
    // setMaxJoinedUsersNum,
  };
};

export default useHomePageBody;
