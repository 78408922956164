import React from "react";
import { User } from "../../../domain/types/User";
import { AmidaEvent } from "../../../domain/types/Event";
import {
  Box as MuiBox,
  Button,
  Typography,
  Paper,
  TextField,
} from "@mui/material";
import EventForm from "../../common/EventForm";
import useEditPageSuccess from "../../hooks/useEditPageSuccess";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "../../common/ScrollToTop";

type Props = {
  amidaEvent: AmidaEvent;
  user: User;
};

const Success = (props: Props) => {
  const { amidaEvent, user } = props;

  const {
    eventTitle,
    setEventTitle,
    bridgeNumRangeMin,
    setBridgeNumRangeMin,
    bridgeNumRangeMax,
    setBridgeNumRangeMax,
    playerLabelWithErrors,
    setPlayerLabelWithErrors,
    playerOthersLabelWithError,
    setPlayerOthersLabelWithError,
    // maxJoinedUsersNum,
    // setMaxJoinedUsersNum,
    updateEvent,
    inputPassword,
    setInputPassword,
  } = useEditPageSuccess(amidaEvent, user);

  const navigate = useNavigate();

  const isLockedUpadateEndEvent = amidaEvent.lockUpdateEnd?.isLock ?? false;
  const textFieldRef = React.useRef<HTMLInputElement>(null);
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault(); // デフォルトの動作を防止
      textFieldRef.current?.blur(); // フォーカスを解除
    }
  };

  return (
    <>
      <ScrollToTop />

      <Paper>
        <Typography
          variant="h1"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          へんしゅう
        </Typography>

        <EventForm
          eventTitle={eventTitle}
          setEventTitle={setEventTitle}
          bridgeNumRangeMin={bridgeNumRangeMin}
          setBridgeNumRangeMin={setBridgeNumRangeMin}
          bridgeNumRangeMax={bridgeNumRangeMax}
          setBridgeNumRangeMax={setBridgeNumRangeMax}
          playerLabelWithErrors={playerLabelWithErrors}
          setPlayerLabelWithErrors={setPlayerLabelWithErrors}
          playerOthersLabelWithError={playerOthersLabelWithError}
          setPlayerOthersLabelWithError={setPlayerOthersLabelWithError}
          // maxJoinedUsersNum={maxJoinedUsersNum}
          // setMaxJoinedUsersNum={setMaxJoinedUsersNum}
        />

        <MuiBox component="div" m={3} />
        {isLockedUpadateEndEvent && (
          <>
            <Typography variant="body1">
              このあみだくじはロックされています。変更するにはパスワードを入力してください。
            </Typography>
            <TextField
              fullWidth
              id="outlined-basic"
              label="パスワードを入力"
              variant="outlined"
              value={inputPassword}
              onChange={(e) => {
                setInputPassword(e.target.value);
              }}
              inputRef={textFieldRef}
              onKeyDown={handleKeyDown}
              inputProps={{
                enterKeyHint: "done",
                type: "text",
              }}
            />
          </>
        )}

        <MuiBox component="div" m={3} />

        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            updateEvent();
          }}
          fullWidth
        >
          OK
        </Button>
        <MuiBox component="div" m={1} />
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            window.confirm("キャンセルしますか？") &&
              navigate("/e/" + amidaEvent.id);
          }}
          fullWidth
        >
          キャンセル
        </Button>
      </Paper>
    </>
  );
};

export default Success;
