import { createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
    palette: {
        primary: {
            main: '#ff6b6b', // 明るいティールブルー（少し暗めに調整）
        },
        secondary: {
            main: '#008b8b', // 明るい赤
        },
        background: {
            default: '#1e1e2f', // ダークブルーグレー
            paper: '#2b2b3c', // やや明るいダークブルーグレー
        },
        text: {
            primary: '#FFFFFF', // 白色のテキスト
            secondary: '#00b3b3', // 明るいティールブルー
        },
        action: {
            active: '#ff6b6b', // アクティブな要素の色（明るい赤）
            hover: '#006b6b', // ホバー時の色（少し暗めに調整）
        },
        warning: {
            main: '#2b2b3c', // 背景に馴染むダークブルーグレー
        },
    },
    typography: {
        fontSize: 14,
        fontFamily: [
            'Roboto',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        button: {
            textTransform: 'none', // ボタンテキストの大文字変換を無効にする
        },
        h1: {
            fontSize: '1.2rem',
            fontWeight: 600, // h1 のフォントウェイトを太くする
        },
        h2: {
            fontSize: '1rem',
        },
        h3: {
            fontSize: '0.9rem',
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    backgroundColor: '#1e1e2f', // 背景色をダークブルーグレーに変更
                    color: '#FFFFFF', // テキスト色を白色に変更
                    '& a': {
                        color: '#00b3b3', // リンクテキストの色を明るいティールブルーに変更
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    color: '#FFFFFF', // ボタンのテキスト色を白色に変更
                    borderRadius: '12px',
                    boxShadow: '8px 8px 16px #1a1a2a, -8px -8px 16px #22223b', // ニューモーフィズムのシャドウ
                    '&:hover:active': {
                        boxShadow: '8px 8px 16px #1a1a2a, -8px -8px 16px #22223b', // ニューモーフィズムのシャドウ
                    },
                    height: '45px',
                    '&:disabled': {
                        color: 'rgba(255, 255, 255, 0.3)', // 無効時のテキスト色
                        backgroundColor: 'rgba(43, 43, 60, 0.5)', // 無効時の背景色 (少し暗めで透明度を追加)
                        boxShadow: 'none', // 無効時の影をなしに
                    },
                },
                //outlined のとき、影を少し弱める
                outlined: {
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', // 軽い影
                    '&:hover': {
                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.15)', // ホバー時に少し強めの影
                    },
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#1e1e2f', // アプリバーの背景色
                    color: '#FFFFFF', // アプリバーのテキスト色
                    borderRadius: '12px',
                    marginBottom: '16px',
                    boxShadow: '8px 8px 16px #1a1a2a, -8px -8px 16px #22223b', // ニューモーフィズムのシャドウ
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: '#141421', // ペーパーコンポーネントの背景色
                    color: '#FFFFFF', // ペーパーコンポーネントのテキスト色
                    borderRadius: '12px',
                    boxShadow: '8px 8px 16px #1a1a2a, -8px -8px 16px #22223b', // ニューモーフィズムのシャドウ
                    padding: '16px 12px', // ペーパーコンポーネントのパディングを小さくする
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    color: '#FFFFFF', // デフォルトのテキストカラーをホワイトに
                    margin: '8px 0',
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiInputBase-root': {
                        backgroundColor: '#2b2b3c', // テキストフィールドの背景色
                        color: '#FFFFFF', // テキストフィールドのテキスト色
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#008b8b', // テキストフィールドの枠線の色
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#00b3b3', // ホバー時の枠線の色
                    },
                    '& .MuiInputLabel-root': {
                        color: '#00b3b3', // ラベルの色
                    },
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    backgroundColor: '#2b2b3c', // Dialog background color
                    borderRadius: '12px',
                    boxShadow: '8px 8px 16px #1a1a2a, -8px -8px 16px #22223b', // Neumorphism shadow
                    padding: '16px',
                    minWidth: '320px',
                },
            },
        },
        MuiDialogContentText: {
            styleOverrides: {
                root: {
                    color: '#FFFFFF', // DialogContentText text color
                    fontSize: '0.9rem',
                },
            },
        },
    },
});

export default darkTheme;
