import { SxProps, TextField, Theme } from "@mui/material";
import React, { useRef, useState } from "react";

type Props = {
  id: string;
  label: string;
  variant?: "standard" | "filled" | "outlined";
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  fullWidth?: boolean;
  helperText?: string;
  error?: boolean;
  sx?: SxProps<Theme> | undefined;
  size?: "small" | "medium";
};

// 日本語入力中かどうかを判別するためのフラグを管理するための状態を持つ
// 日本語入力中の場合は、Enter キーを押してもフォーカスが外れないようにする

const MyTextFfield = (props: Props) => {
  const {
    id,
    label,
    variant,
    value,
    onChange,
    fullWidth,
    helperText,
    error,
    sx,
    size,
  } = props;

  const textFieldRef = useRef<HTMLInputElement>(null);
  const [isComposing, setIsComposing] = useState(false); // 日本語入力中かどうかの状態を管理
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && !isComposing) {
      event.preventDefault(); // デフォルトの動作を防止
      textFieldRef.current?.blur(); // フォーカスを解除
    }
  };
  // 日本語入力中かどうかを判別するイベントハンドラ
  const handleComposition = (isStart: boolean) => {
    setIsComposing(isStart);
  };

  return (
    <TextField
      id={id}
      label={label}
      fullWidth={fullWidth}
      variant={variant}
      value={value}
      onChange={onChange}
      inputRef={textFieldRef}
      onKeyDown={handleKeyDown}
      onCompositionStart={() => handleComposition(true)}
      onCompositionEnd={() => handleComposition(false)}
      inputProps={{
        enterKeyHint: "done",
        type: "text",
      }}
      helperText={helperText}
      error={error}
      sx={sx}
      size={size}
    />
  );
};

export default MyTextFfield;
