import React from "react";
import {
  Typography,
  Button,
  Box as MuiBox,
  List,
  ListItem,
  ListItemIcon,
  Tooltip,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import MyTextFfield from "./MyTextField";

type PlayerLabelsFormProps = {
  playerLabelWithErrors: { label: string; error: boolean }[];
  setPlayerLabelWithErrors: React.Dispatch<
    React.SetStateAction<{ label: string; error: boolean }[]>
  >;
  playerOthersLabelWithError: { label: string; error: boolean };
  setPlayerOthersLabelWithError: React.Dispatch<
    React.SetStateAction<{ label: string; error: boolean }>
  >;
};

const PlayerLabelsForm = (props: PlayerLabelsFormProps) => {
  const {
    playerLabelWithErrors,
    setPlayerLabelWithErrors,
    playerOthersLabelWithError,
    setPlayerOthersLabelWithError,
  } = props;

  const updatePlayerLabel = (index: number, value: string) => {
    const newPlayerLabelWithErrors = playerLabelWithErrors.map(
      (playerLabel, idx) =>
        idx === index ? { label: value, error: value.length > 20 } : playerLabel
    );
    setPlayerLabelWithErrors(newPlayerLabelWithErrors);
  };

  const handleDelete = (index: number) => {
    const newPlayerLabelWithErrors = playerLabelWithErrors.filter(
      (_, idx) => idx !== index
    );
    setPlayerLabelWithErrors(newPlayerLabelWithErrors);
  };

  const handleAddLabel = () => {
    setPlayerLabelWithErrors([
      ...playerLabelWithErrors,
      { label: "", error: false },
    ]);
  };

  const updatePlayerOthersLabel = (value: string) => {
    setPlayerOthersLabelWithError({
      label: value,
      error: value.length > 20,
    });
  };

  return (
    <MuiBox component="div">
      <Typography variant="h1">振り分け</Typography>

      <MuiBox display="flex" alignItems="center" component="div">
        <Typography variant="h2">振り分け名</Typography>
        <Tooltip title={"振り分けを追加する"}>
          <IconButton
            aria-label={"振り分けを追加する"}
            color="secondary"
            onClick={handleAddLabel}
            size="small"
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      </MuiBox>

      {playerLabelWithErrors.length === 0 && (
        <Typography variant="body1" color="textSecondary">
          振り分け名なしの場合は、数字 (1, 2, 3, ...) で振り分けられます
        </Typography>
      )}
      <List aria-label="player labels" style={{ padding: 0 }}>
        {playerLabelWithErrors.map((playerLabelWithError, index) => (
          <ListItem
            key={index}
            sx={{
              paddingBottom: 0,
              paddingTop: 0,
              "&:not(:last-child)": {
                marginBottom: 1,
              },
            }}
          >
            <MyTextFfield
              id={`outlined-basic-${index}`}
              label={index === 0 ? "例: 大当たり" : "例: 当たり"}
              value={playerLabelWithError.label}
              onChange={(e) => updatePlayerLabel(index, e.target.value)}
              helperText={
                playerLabelWithError.error
                  ? "振り分け名は20文字以内で入力してください"
                  : ""
              }
              error={playerLabelWithError.error}
              size="small"
            />

            <MuiBox m={0.1} component="div" />

            <ListItemIcon>
              <Button
                sx={{
                  height: "40px",
                  borderRadius: "2px",
                  shadow: "none",
                  boxShadow: "none",
                  "&:hover": {
                    boxShadow: "none",
                  },
                }}
                variant="contained"
                color="secondary"
                aria-label="ラベルを削除"
                onClick={() => handleDelete(index)}
              >
                <DeleteIcon />
              </Button>
            </ListItemIcon>
          </ListItem>
        ))}
      </List>

      <Typography variant="h2">その他すべて</Typography>

      <MuiBox component="div" mx={2}>
        <MyTextFfield
          id={"others-label"}
          label={"例: はずれ"}
          value={playerOthersLabelWithError.label}
          onChange={(e) => updatePlayerOthersLabel(e.target.value)}
          helperText={
            playerOthersLabelWithError.error
              ? "その他は20文字以内で入力してください"
              : ""
          }
          error={playerOthersLabelWithError.error}
          size="small"
        />
      </MuiBox>
    </MuiBox>
  );
};

export default PlayerLabelsForm;
