import React from "react";
import { Typography, Box as MuiBox, Button } from "@mui/material";
import PlayerLabelsForm from "./PlayerLabelsForm";
import BridgePreview from "./BridgePreview";
import MyTextFfield from "./MyTextField";
// import NumericField from "./NumericField";

type eventFormProps = {
  setEventTitle: React.Dispatch<React.SetStateAction<string>>;
  setBridgeNumRangeMin: React.Dispatch<React.SetStateAction<number>>;
  setBridgeNumRangeMax: React.Dispatch<React.SetStateAction<number>>;
  setPlayerLabelWithErrors: React.Dispatch<
    React.SetStateAction<{ label: string; error: boolean }[]>
  >;
  eventTitle: string;
  bridgeNumRangeMin: number;
  bridgeNumRangeMax: number;
  playerLabelWithErrors: { label: string; error: boolean }[];

  playerOthersLabelWithError: { label: string; error: boolean };
  setPlayerOthersLabelWithError: React.Dispatch<
    React.SetStateAction<{ label: string; error: boolean }>
  >;
  // maxJoinedUsersNum: number;
  // setMaxJoinedUsersNum: React.Dispatch<React.SetStateAction<number>>;
};

const EventForm = (props: eventFormProps) => {
  const {
    setEventTitle,
    // setBridgeNumRangeMin,
    setBridgeNumRangeMax,
    setPlayerLabelWithErrors,
    eventTitle,
    // bridgeNumRangeMin,
    bridgeNumRangeMax,
    playerLabelWithErrors,

    playerOthersLabelWithError,
    setPlayerOthersLabelWithError,
    // maxJoinedUsersNum,
    // setMaxJoinedUsersNum,
  } = props;
  //最低限も決められる機能は不要なのでコメントアウト
  // const handleBridgeNumRangeChange = (
  //   event: any,
  //   newValue: number | number[]
  // ) => {
  //   if (typeof newValue === "number") {
  //     return;
  //   }
  //   setBridgeNumRangeMin(newValue[0]);
  //   setBridgeNumRangeMax(newValue[1]);
  // };

  //slider を使わなくなったので、コメントアウト
  // const handleBridgeNumRangeChange = (
  //   event: any,
  //   newValue: number | number[]
  // ) => {
  //   if (typeof newValue !== "number") {
  //     return;
  //   }
  //   setBridgeNumRangeMin(2);
  //   setBridgeNumRangeMax(newValue);
  // };

  return (
    <>
      <MuiBox component="div">
        <Typography variant="h1">タイトル</Typography>
        <MyTextFfield
          id="outlined-basic"
          label="例: 抽選会"
          fullWidth
          variant="outlined"
          value={eventTitle}
          onChange={(e) => setEventTitle(e.target.value)}
        />
      </MuiBox>

      {/* <MuiBox component="div">
        <Typography variant="h1">最大参加人数</Typography>
        <NumericField
          id="max-joined-users-num-input"
          state={maxJoinedUsersNum}
          setState={setMaxJoinedUsersNum}
          label="最大参加人数"
          variant="outlined"
          style={{ width: "200px" }}
          required
          fullWidth
          onKeyDown={handleKeyDown}
        />
      </MuiBox> */}

      <MuiBox m={3} component="div" />

      <PlayerLabelsForm
        playerLabelWithErrors={playerLabelWithErrors}
        setPlayerLabelWithErrors={setPlayerLabelWithErrors}
        playerOthersLabelWithError={playerOthersLabelWithError}
        setPlayerOthersLabelWithError={setPlayerOthersLabelWithError}
      />

      <MuiBox m={3} component="div" />

      <Typography variant="h1">あみだの複雑度</Typography>
      <Typography variant="body1" color="textSecondary">
        ひとりの参加者が伸ばす横棒の数を設定できます
      </Typography>
      <MuiBox display="flex" alignItems="center" component="div">
        <Button
          variant="contained"
          color="secondary"
          size="small"
          onClick={() => {
            const newValue = Math.max(2, bridgeNumRangeMax - 1);
            setBridgeNumRangeMax(newValue);
          }}
        >
          -
        </Button>
        <Typography variant="body1" style={{ margin: "0 10px" }}>
          {bridgeNumRangeMax}
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          onClick={() => {
            const newValue = Math.min(5, bridgeNumRangeMax + 1);
            setBridgeNumRangeMax(newValue);
          }}
        >
          +
        </Button>
      </MuiBox>

      <MuiBox m={3} component="div" />

      <hr style={{ width: "100%", margin: "20px 0" }} />

      <Typography variant="h1">プレビュー</Typography>
      <BridgePreview
        bridgeNumRangeMax={bridgeNumRangeMax}
        playerLabels={playerLabelWithErrors.map(
          (playerLabelWithError) => playerLabelWithError.label
        )}
        playerOthersLabel={playerOthersLabelWithError.label}
        playerNum={Math.max(4, playerLabelWithErrors.length + 1)}
      />
    </>
  );
};

export default EventForm;
