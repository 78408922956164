import React from "react";
import { Player } from "../../../../domain/types/Player";
import { CatmullRomCurve3, Vector3 } from "three";
import { Text } from "@react-three/drei";

type Props = {
  jsonObj?: EachPlayerJsonObj;
  playersSortedByStartIndex: Player[];
  myPlayerIndex?: number;
  playerLabels: string[];
};

export interface EachPlayerJsonObj {
  type: EachPlayerType;
  obj: string;
  axis: Axis;
}

enum EachPlayerType {
  MOVE = "move",
  LONG = "long",
}

enum Axis {
  X = "x",
  Y = "y",
}

const EachPlayer = (props: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { jsonObj, playersSortedByStartIndex, myPlayerIndex, playerLabels } =
    props;

  //   const { type, obj, axix } = jsonObj;

  const tubularSegments = 20;
  // 線の太さ
  const radius = 0.02;
  const radialSegments = 8;

  // fontSize を計算する関数を追加
  const calculateFontSize = (text: string) => {
    if (text.length <= 3) {
      return 0.15;
    } else if (text.length <= 7) {
      return 0.15;
    } else {
      return 0.1;
    }
  };

  // 文字列を指定した文字数ごとに分割する関数
  const splitText = (text: string, maxLength: number) => {
    const result = [];
    for (let i = 0; i < text.length; i += maxLength) {
      if (result.length > 2) {
        result.push("...");
        break;
      }
      result.push(text.substring(i, i + maxLength));
    }
    return result;
  };

  // プレイヤーの色を設定
  const palyerColors = ["#0047AB", "#20B2AA", "#B0E0E6", "#AFEEEE", "#87CEEB"];
  const getPlayerColor = (playerIndex: number) => {
    return palyerColors[playerIndex % palyerColors.length];
  };
  return (
    <>
      {playersSortedByStartIndex.map((player, index) => {
        const depth = 1;
        const gap = (index - (playersSortedByStartIndex.length - 1) / 2) * 1;
        const vertexVertical = [gap, -1, depth, gap, 1, depth];
        const veticalPoints: Vector3[] = [];
        for (let i = 0; i < vertexVertical.length; i += 3) {
          veticalPoints.push(
            new Vector3(
              vertexVertical[i],
              vertexVertical[i + 1],
              vertexVertical[i + 2]
            )
          );
        }
        const verticalPath = new CatmullRomCurve3(veticalPoints, true);

        return (
          <React.Fragment key={index + "eachPlayer"}>
            {/* 上の文字（プレイヤー名）を表示 */}
            {splitText(player.displayName?.val ?? "", 7).map(
              (line, lineIndex) => (
                <Text
                  key={lineIndex}
                  position={[gap, 1 - lineIndex * 0.12, depth + 0.2]}
                  color={"#1B263B"}
                  fontSize={calculateFontSize(player.displayName?.val ?? "")}
                  maxWidth={10}
                  lineHeight={1}
                  letterSpacing={0.02}
                  textAlign={"center"}
                  font="https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vaqI.woff"
                  anchorX="center"
                  anchorY="middle"
                  outlineColor={myPlayerIndex === index ? "#ff6b6b" : "#ffffff"}
                  outlineWidth={0.02}
                >
                  {line}
                </Text>
              )
            )}
            {/* 下の文字（プレイヤーのラベル、役割り）を表示 */}
            {splitText(playerLabels[index], 7).map((line, lineIndex) => (
              <Text
                key={lineIndex}
                position={[gap, lineIndex * -0.12 - 1, depth + 0.2]} // Y位置を調整してテキストを重ねない
                color={"#333333"}
                // ゴールのプレイヤーの文字色を変える
                // outlineColor={
                //   myPlayerIndex !== undefined &&
                //   playersSortedByStartIndex[myPlayerIndex]?.ball?.goalIndex ===
                //     index
                //     ? "#ff6b6b"
                //     : "#ffffff"
                // }
                outlineColor={"#ffffff"}
                outlineWidth={0.02}
                fontSize={calculateFontSize(playerLabels[index])}
                maxWidth={10}
                lineHeight={1}
                letterSpacing={0.02}
                textAlign={"center"}
                font="https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vaqI.woff"
                anchorX="center"
                anchorY="middle"
              >
                {line}
              </Text>
            ))}

            {/* 縦棒を表示 */}
            <mesh>
              <tubeGeometry
                args={[
                  verticalPath,
                  tubularSegments,
                  radius,
                  radialSegments,
                  true,
                ]}
              />
              <meshStandardMaterial color={getPlayerColor(index)} />
            </mesh>
          </React.Fragment>
        );
      })}
    </>
  );
};

export default EachPlayer;
