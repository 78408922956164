import React, { useEffect, useRef, useState } from "react";
import { Bridge, Direction } from "../../../domain/types/Bridge";
import { Environment } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import * as THREE from "three";
import {
  Button,
  IconButton,
  Box as MuiBox,
  Tooltip,
  Typography,
  Slider,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import { AmidaEvent } from "../../../domain/types/Event";
import { User } from "../../../domain/types/User";
import { generateUUID } from "three/src/math/MathUtils";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { ActionCreatorWithoutPayload } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

type Props = {
  amidaEvent: AmidaEvent;
  user: User;
  myBridges: Bridge[];
  setMyBridges: (bridges: Bridge[]) => void;
  enableEditing: ActionCreatorWithoutPayload<"event/enableEditing">;
  selectedMyBridgeIndex: number | null;
  setSelectedMyBridgeIndex: (index: number | null) => void;
  isTriggerdUpdateMyBridges: boolean;
};

const AmidaInput = (props: Props) => {
  const {
    amidaEvent,
    user,
    myBridges,
    setMyBridges,
    enableEditing,
    selectedMyBridgeIndex,
    setSelectedMyBridgeIndex,
    isTriggerdUpdateMyBridges,
  } = props;

  const dispatch = useDispatch();

  const [myPlayerBridges, setMyPlayerBridges] = useState<Bridge[]>([]);
  const [otherBridgePairs, setOtherBridgePairs] = useState<Bridge[][]>([]);

  const [ellipseOffset, setEllipseOffset] = useState(0.7);

  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    const otherPlayers = amidaEvent.players.filter((p) => p.userId !== user.id);
    const otherBridgePairs = otherPlayers.map((p) => p.bridges);
    setOtherBridgePairs(otherBridgePairs);

    if (user.id === undefined) {
      setMyPlayerBridges([]);
      return;
    }
    const myPlayer = amidaEvent.players.find((p) => p.userId === user.id);
    if (myPlayer === undefined) {
      setMyPlayerBridges([]);
      return;
    }

    setMyPlayerBridges(myPlayer.bridges);
    setMyBridges(myPlayer.bridges);
  }, [amidaEvent.players, setMyBridges, user.id]);

  useEffect(() => {
    const speed = 0.0003;

    const interval = setInterval(() => {
      setEllipseOffset((prev) => (prev + speed) % 1);
    }, 50);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (isTriggerdUpdateMyBridges) {
      // 更新されたときに、自分の横棒が後ろにくるように来るように回転させる
      setEllipseOffset(0.75);
    }
  }, [isTriggerdUpdateMyBridges, enableEditing]);

  useEffect(() => {
    if (isTriggerdUpdateMyBridges) {
      setIsAnimating(true);
      const timeout = setTimeout(() => {
        setIsAnimating(false);
      }, 3000); // 3秒間アニメーションを続ける
      return () => clearTimeout(timeout);
    }
  }, [isTriggerdUpdateMyBridges]);

  const handleAddBridge = (direction: Direction) => {
    if (amidaEvent.bridgeNumRange === undefined) {
      return;
    }
    if (myBridges.length >= amidaEvent.bridgeNumRange.max) {
      alert("これ以上追加できません");
      return;
    }
    const rondomRelativeHeight = Math.random() * 0.98 + 0.01;
    setMyBridges([
      ...myBridges,
      {
        id: generateUUID(),
        playerUuid: undefined,
        direction: direction,
        relativeHeight: rondomRelativeHeight,
      },
    ]);
    setSelectedMyBridgeIndex(myBridges.length);
  };

  const tubularSegments = 20;
  const radius = 0.05;
  const radialSegments = 8;
  const pointsNum = 20;

  const vertexVertical = [0, -1, 0, 0, 1, 0];
  const veticalPoints: THREE.Vector3[] = [];
  for (let i = 0; i < vertexVertical.length; i += 3) {
    veticalPoints.push(
      new THREE.Vector3(
        vertexVertical[i],
        vertexVertical[i + 1],
        vertexVertical[i + 2]
      )
    );
  }
  const verticalPath = new THREE.CatmullRomCurve3(veticalPoints, true);

  const getAddBlidgeButtonColor = (direction: Direction) => {
    if (amidaEvent.bridgeNumRange === undefined) {
      return "primary";
    }
    if (myBridges.length < amidaEvent.bridgeNumRange.min - 1) {
      return "primary";
    }

    return myBridges.find((b) => b.direction === direction) === undefined
      ? "primary"
      : "secondary";
  };

  const getAddBlidgeButtonDisabled = (direction: Direction) => {
    if (amidaEvent.bridgeNumRange === undefined) {
      return true;
    }
    const isExitOtherDirection = myBridges.find(
      (b) => b.direction !== direction
    );
    if (
      myBridges.length >= amidaEvent.bridgeNumRange.max - 1 &&
      !isExitOtherDirection
    ) {
      return true;
    }
    if (myBridges.length >= amidaEvent.bridgeNumRange.max) {
      return true;
    }
    return false;
  };

  const getSlicerValue = () => {
    if (selectedMyBridgeIndex === null) {
      return 50;
    }
    if (
      myBridges[selectedMyBridgeIndex] === null ||
      myBridges[selectedMyBridgeIndex] === undefined
    ) {
      return 50;
    }
    const selectedMyBridge = myBridges[selectedMyBridgeIndex];
    if (selectedMyBridge.relativeHeight === undefined) {
      return 50;
    }
    return selectedMyBridge.relativeHeight * 100;
  };

  const renderer = useRef<THREE.WebGLRenderer | null>(null);
  useEffect(() => {
    return () => {
      if (renderer.current) {
        renderer.current.dispose();
        renderer.current = null;
      }
    };
  }, []);

  const createEllipsePath = (a: number, b: number, segments: number) => {
    const points: THREE.Vector3[] = [];
    for (let i = 0; i <= segments; i++) {
      const theta = (i / segments) * Math.PI * 2;
      const x = a * Math.cos(theta);
      const z = b * Math.sin(theta);
      points.push(new THREE.Vector3(x, 0, z));
    }

    return new THREE.CatmullRomCurve3(points);
  };

  const ellipsePath = createEllipsePath(5.0, 2.0, 50); // 楕円をゆるくする

  return (
    <MuiBox
      component="div"
      height={500}
      position="relative"
      style={{
        userSelect: "none",
        WebkitUserSelect: "none",
      }}
    >
      <Canvas
        camera={{ position: [0, 0.1, 2] }}
        onCreated={({ gl }) => {
          renderer.current = gl;
        }}
      >
        <color attach="background" args={["#f0f8ff"]} />
        <Environment
          files={["px.png", "nx.png", "py.png", "ny.png", "pz.png", "nz.png"]}
          path="/images/"
          background
        />

        <ambientLight intensity={0.8} color={"#ffffff"} />
        <pointLight position={[0, 0, 0]} intensity={1.5} color={"#ffffff"} />

        <fog attach="fog" color={"#f0f8ff"} near={1} far={10} />
        {/* 自分の縦棒 */}
        <mesh key={"my_vertical"}>
          <tubeGeometry
            args={[verticalPath, tubularSegments, radius, radialSegments, true]}
          />
          <meshToonMaterial
            color="white"
            emissive={
              isAnimating ? new THREE.Color("yellow") : new THREE.Color("black")
            }
            emissiveIntensity={isAnimating ? 0.5 : 0}
          />
        </mesh>
        {/* 自分の横棒 */}
        {myBridges.map((bridge, index) => {
          var height = bridge.relativeHeight;
          if (height === undefined) {
            return null;
          }
          height = (height - 0.5) * 1.9;
          const from = new THREE.Vector3(
            bridge.direction === Direction.Left ? -1 : 1,
            height,
            0
          );
          const to = new THREE.Vector3(0, height, 0);
          return (
            <mesh
              key={bridge.id}
              onClick={(e) => setSelectedMyBridgeIndex(index)}
            >
              <tubeGeometry
                args={[
                  new THREE.CatmullRomCurve3([from, to], true),
                  tubularSegments,
                  radius,
                  radialSegments,
                  true,
                ]}
              />
              <meshStandardMaterial
                color={
                  index === selectedMyBridgeIndex
                    ? "orange"
                    : isAnimating
                    ? "yellow"
                    : "white"
                }
                emissive={
                  isAnimating
                    ? new THREE.Color("yellow")
                    : new THREE.Color("black")
                }
                emissiveIntensity={isAnimating ? 0.5 : 0}
              />
            </mesh>
          );
        })}

        {ellipsePath.getPoints(pointsNum).map((point, index) => {
          const offsetPoint = ellipsePath.getPoint(
            (index / pointsNum + ellipseOffset) % 1
          );
          const from = new THREE.Vector3(offsetPoint.x, -1, offsetPoint.z);
          const to = new THREE.Vector3(offsetPoint.x, 1, offsetPoint.z);
          return (
            <React.Fragment key={index + "other"}>
              <mesh key={`ellipse_${index}`}>
                <tubeGeometry
                  args={[
                    new THREE.CatmullRomCurve3([from, to], false),
                    tubularSegments,
                    radius,
                    radialSegments,
                    true,
                  ]}
                />
                <meshStandardMaterial
                  color={"gray"}
                  opacity={0.5}
                  transparent={true}
                  //自分の縦棒はアニメーションするようにする
                  emissive={
                    index === pointsNum - 1 && isAnimating
                      ? new THREE.Color("yellow")
                      : new THREE.Color("black")
                  }
                  emissiveIntensity={
                    index === pointsNum - 1 && isAnimating ? 0.5 : 0
                  }
                />
              </mesh>
              {/* 自分の横棒 */}
              {index === pointsNum - 1 &&
                myPlayerBridges.map((bridge, bridgeIndex) => {
                  var height = bridge.relativeHeight;
                  if (height === undefined) {
                    return null;
                  }
                  height = (height - 0.5) * 1.75;
                  const from = new THREE.Vector3(
                    bridge.direction === Direction.Left
                      ? -1 + offsetPoint.x
                      : 1 + offsetPoint.x,
                    height,
                    bridge.direction === Direction.Left
                      ? offsetPoint.z
                      : offsetPoint.z
                  );
                  const to = new THREE.Vector3(
                    bridge.direction === Direction.Left
                      ? offsetPoint.x
                      : offsetPoint.x,
                    height,
                    offsetPoint.z
                  );
                  return (
                    <mesh key={bridge.id}>
                      <tubeGeometry
                        args={[
                          new THREE.CatmullRomCurve3([from, to], false),
                          tubularSegments,
                          radius,
                          radialSegments,
                          true,
                        ]}
                      />
                      <meshStandardMaterial
                        color={"gray"}
                        opacity={0.5}
                        transparent={true}
                        emissive={
                          isAnimating
                            ? new THREE.Color("yellow")
                            : new THREE.Color("black")
                        }
                        emissiveIntensity={isAnimating ? 0.5 : 0}
                      />
                    </mesh>
                  );
                })}
              {/* 横棒を表示 */}
              {/* ２本に一本の割合で横棒を表示する  */}
              {index % 2 === 0 &&
              otherBridgePairs.length > index / 2 &&
              index / 2 !== pointsNum - 1
                ? otherBridgePairs[index / 2].map((bridge, bridgeIndex) => {
                    var height = bridge.relativeHeight;
                    if (height === undefined) {
                      return null;
                    }
                    height = (height - 0.5) * 1.75;
                    const from = new THREE.Vector3(
                      bridge.direction === Direction.Left
                        ? -1 + offsetPoint.x
                        : 1 + offsetPoint.x,
                      height,
                      bridge.direction === Direction.Left
                        ? offsetPoint.z
                        : offsetPoint.z
                    );
                    const to = new THREE.Vector3(
                      bridge.direction === Direction.Left
                        ? offsetPoint.x
                        : offsetPoint.x,
                      height,
                      offsetPoint.z
                    );
                    return (
                      <mesh key={`bridge_${index}_${bridgeIndex}`}>
                        <tubeGeometry
                          args={[
                            new THREE.CatmullRomCurve3([from, to], false),
                            tubularSegments,
                            radius,
                            radialSegments,
                            true,
                          ]}
                        />
                        <meshStandardMaterial
                          color={"gray"}
                          opacity={0.5}
                          transparent={true}
                        />
                      </mesh>
                    );
                  })
                : null}
            </React.Fragment>
          );
        })}
      </Canvas>
      <MuiBox
        component="div"
        position="absolute"
        top="0"
        width="100%"
        display="flex"
        justifyContent="space-around"
      >
        <Tooltip title="左に横棒を追加">
          <Button
            variant="contained"
            color={getAddBlidgeButtonColor(Direction.Left)}
            disabled={getAddBlidgeButtonDisabled(Direction.Left)}
            onClick={() => {
              handleAddBridge(Direction.Left);
              dispatch(enableEditing());
            }}
            sx={{ boxShadow: "none", margin: "10px" }}
          >
            <ArrowLeftIcon sx={{ color: "white" }} />
            左に追加
          </Button>
        </Tooltip>
        <MuiBox component="div" m={1} />
        <Tooltip title="右に横棒を追加">
          <Button
            variant="contained"
            color={getAddBlidgeButtonColor(Direction.Right)}
            disabled={getAddBlidgeButtonDisabled(Direction.Right)}
            onClick={() => {
              handleAddBridge(Direction.Right);
              dispatch(enableEditing());
            }}
            sx={{ boxShadow: "none", margin: "10px" }}
          >
            右に追加
            <ArrowRightIcon sx={{ color: "white" }} />
          </Button>
        </Tooltip>
        <MuiBox component="div" position="absolute" top="100px" height="300px">
          <Slider
            orientation="vertical"
            defaultValue={50}
            aria-label="vertical slider"
            disabled={selectedMyBridgeIndex === null}
            value={getSlicerValue()}
            max={99.9}
            min={0.1}
            onChange={(e: any, newValue: any) => {
              if (typeof newValue !== "number") {
                return;
              }
              if (selectedMyBridgeIndex === null) {
                return;
              }
              const newLocal: number = newValue / 100;
              const newBridges = myBridges.map((bridge, i) => {
                if (i === selectedMyBridgeIndex) {
                  return { ...bridge, relativeHeight: newLocal };
                }
                return bridge;
              });
              setMyBridges(newBridges);
              dispatch(enableEditing());
            }}
          />
        </MuiBox>

        <MuiBox
          component="div"
          position="absolute"
          top="420px"
          width="100%"
          display="flex"
          justifyContent="space-around"
        >
          <Typography variant="body2">
            {myBridges.length} / {amidaEvent.bridgeNumRange?.max} (
            {amidaEvent.bridgeNumRange?.min} 以上)
          </Typography>
        </MuiBox>

        <MuiBox
          component="div"
          position="absolute"
          top="400px"
          height="50px"
          width="50px"
          margin="30px"
          right="0"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          borderRadius="50%"
          bgcolor="rgba(255, 255, 255, 0.6)"
          boxShadow={3}
        >
          <IconButton
            color="secondary"
            aria-label="delete"
            onClick={() => {
              if (selectedMyBridgeIndex === null) {
                return;
              }
              setMyBridges(
                myBridges.filter((bridge, i) => i !== selectedMyBridgeIndex)
              );
              setSelectedMyBridgeIndex(null);
              dispatch(enableEditing());
            }}
            disabled={selectedMyBridgeIndex === null}
          >
            <Delete fontSize="large" />
          </IconButton>
        </MuiBox>
      </MuiBox>
    </MuiBox>
  );
};

export default AmidaInput;
