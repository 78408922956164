import {
  Button,
  Paper,
  Typography,
  Box as MuiBox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import React, { useState } from "react";
import DoneIcon from "@mui/icons-material/Done";
import QRCode from "qrcode.react";

type Props = {
  title: string;
  eventId: string;
  eventTitle: string;
};

function copyToClipboard(text: string) {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(text).catch((err) => {
      // クリップボードAPIが利用できない場合のフォールバック
      const textarea = document.createElement("textarea");
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
    });
  } else {
    // クリップボードAPIが全く利用できない場合
    const textarea = document.createElement("textarea");
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
  }
}

const ShareEvent = (props: Props) => {
  const { title, eventId, eventTitle } = props;

  const [copied, setCopied] = useState(false);
  const [isQrCodeModalOpen, setIsQrCodeModalOpen] = useState(false);

  const handleCopy = () => {
    copyToClipboard(`${window.location.origin}/e/${eventId}`);
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  };

  return (
    <Paper>
      <Typography variant="h1">{title}</Typography>

      <MuiBox component="div" display="flex" flexDirection="row">
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          onClick={() => {
            copyToClipboard(`${window.location.origin}/e/${eventId}`);
            handleCopy();
          }}
        >
          {!copied && <>URLをコピーする</>}
          {copied && <>コピー完了</>}
          {copied && <DoneIcon color="primary" fontSize="medium" />}
        </Button>

        <MuiBox component="div" m={1} />
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          onClick={() => {
            window.open(
              `https://line.me/R/msg/text/?${window.location.origin}/e/${eventId}?openExternalBrowser=1`
            );
          }}
        >
          LINEで共有する
        </Button>
      </MuiBox>

      <MuiBox component="div" m={2} />
      <Button
        fullWidth
        variant="contained"
        color="secondary"
        onClick={() => setIsQrCodeModalOpen(true)}
      >
        QRコードを表示する
      </Button>

      <Dialog
        open={isQrCodeModalOpen}
        onClose={() => setIsQrCodeModalOpen(false)}
      >
        <DialogTitle>
          {eventTitle}の{title}
        </DialogTitle>
        <DialogContent
          style={{
            display: "flex",
            justifyContent: "center",
            overflow: "auto",
            padding: "16px",
            backgroundColor: "white",
            width: "100%",
            height: "100%",
          }}
        >
          <QRCode
            value={`${window.location.origin}/e/${eventId}`}
            style={{ width: "100%", height: "100%" }}
          />{" "}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsQrCodeModalOpen(false)}
            variant="contained"
            color="secondary"
          >
            <Typography variant="body1">閉じる</Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default ShareEvent;
