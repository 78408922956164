import { AmidaEvent } from "../../../domain/types/Event";
import { User } from "../../../domain/types/User";
import ShareEvent from "../../common/ShareEvent";
import { Paper, Typography, Box as MuiBox, Button } from "@mui/material";
import AmidaResult from "./AmidaResult";
import useEventPageSuccessClosed from "../../hooks/useEventPageSuccessClosed";
import ScrollToTop from "../../common/ScrollToTop";
import ResultTable from "./ResultTable";

type Props = {
  amidaEvent: AmidaEvent;
  user: User;
};

const SuccessClosed = (props: Props) => {
  const { amidaEvent, user } = props;
  const {
    playersSortedByStartIndex,
    myPlayerIndex,
    playerLabels,
    playersSortedByGoalIndex,
    rowsToShow,
    isShowAllRows,
    onClickShowMore,
  } = useEventPageSuccessClosed(amidaEvent, user);

  return (
    <>
      <ScrollToTop />
      <Paper>
        <Typography variant="h1">結果: {amidaEvent.title?.val}</Typography>

        <MuiBox component="div" m={1} />

        <AmidaResult
          playersSortedByStartIndex={playersSortedByStartIndex}
          myPlayerIndex={myPlayerIndex ?? 0}
          playerLabels={playerLabels}
          playersSortedByGoalIndex={playersSortedByGoalIndex}
        />

        <MuiBox component="div" m={2} />

        <ResultTable
          rowsToShow={rowsToShow}
          isShowAllRows={isShowAllRows}
          onClickShowMore={onClickShowMore}
        />
      </Paper>

      <MuiBox component="div" m={2} />

      {amidaEvent.id && (
        <ShareEvent
          title={"シェア"}
          eventId={amidaEvent.id}
          eventTitle={amidaEvent.title?.val ?? ""}
        />
      )}

      <MuiBox component="div" m={3} />

      <Paper>
        <Button href="/" variant="contained" color="secondary" fullWidth>
          新しくはじめる
        </Button>
      </Paper>
    </>
  );
};

export default SuccessClosed;
