import React, { useEffect, useState } from "react";
import { Player } from "../../../../domain/types/Player";
import { CatmullRomCurve3, Vector3 } from "three";
import { Direction } from "../../../../domain/types/Bridge";

type Props = {
  jsonObj?: BridgeJsonObj;
  playersSortedByStartIndex: Player[];
  myPlayerIndex?: number;
  playerLabels: string[];
};

export interface BridgeJsonObj {
  type: BridgeType;
  obj: string;
}

enum BridgeType {
  MOVE = "move",
  LONG = "long",
}

const Bridge = (props: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { jsonObj, playersSortedByStartIndex, myPlayerIndex, playerLabels } =
    props;

  //   const { type, obj, axix } = jsonObj;

  const tubularSegments = 20;
  // 線の太さ
  const radius = 0.02;
  const radialSegments = 8;

  // プレイヤーの色を設定
  const palyerColors = ["#0047AB", "#20B2AA", "#B0E0E6", "#AFEEEE", "#87CEEB"];
  const getPlayerColor = (playerIndex: number) => {
    return palyerColors[playerIndex % palyerColors.length];
  };

  const getHorizontalBridgeColor = (
    playerIndex: number,
    playerNum: number,
    direction: Direction
  ) => {
    switch (direction) {
      case Direction.Left:
        if (playerIndex === 0) {
          return "white";
        }
        return getPlayerColor(playerIndex);
      case Direction.Right:
        if (playerIndex === playerNum - 1) {
          return "white";
        }
        return getPlayerColor(playerIndex);
      default:
        return "white";
    }
  };

  // 端はみ出た横棒を透明にするためのアニメーション
  const [opacity, setOpacity] = useState(1);
  useEffect(() => {
    let start = Date.now();
    const duration = 3000; // 3秒間かけて透明にする

    const animate = () => {
      const now = Date.now();
      const elapsed = now - start;
      const progress = Math.min(elapsed / duration, 1);
      setOpacity(1 - progress);

      if (progress < 1) {
        requestAnimationFrame(animate);
      }
    };

    const timer = setTimeout(() => {
      requestAnimationFrame(animate);
    }, 2000); // 2秒後にアニメーションを開始

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <>
      {playersSortedByStartIndex.map((player, index) => {
        const bridges = player.bridges;
        const depth = 1;
        const gap = (index - (playersSortedByStartIndex.length - 1) / 2) * 1;
        const vertexVertical = [gap, -1, depth, gap, 1, depth];
        const veticalPoints: Vector3[] = [];
        for (let i = 0; i < vertexVertical.length; i += 3) {
          veticalPoints.push(
            new Vector3(
              vertexVertical[i],
              vertexVertical[i + 1],
              vertexVertical[i + 2]
            )
          );
        }

        return (
          <React.Fragment key={index + "bridge"}>
            {/* 横棒を表示 */}
            {bridges.map((bridge) => {
              var height = bridge.relativeHeight;
              if (height === undefined) {
                return null;
              }
              height = (height - 0.5) * 2;
              const from = new Vector3(
                bridge.direction === Direction.Left ? -1 + gap : 1 + gap,
                height,
                depth
              );
              const to = new Vector3(gap, height, depth);
              return (
                <mesh key={bridge.id}>
                  <tubeGeometry
                    args={[
                      new CatmullRomCurve3([from, to], true),
                      tubularSegments,
                      radius,
                      radialSegments,
                      true,
                    ]}
                  />
                  <meshStandardMaterial
                    color={getHorizontalBridgeColor(
                      index,
                      playersSortedByStartIndex.length,
                      bridge.direction ?? Direction.Undefined
                    )}
                    // white の場合は透明度を設定
                    transparent={true}
                    opacity={
                      getHorizontalBridgeColor(
                        index,
                        playersSortedByStartIndex.length,
                        bridge.direction ?? Direction.Undefined
                      ) === "white"
                        ? opacity
                        : 1
                    }
                  />
                </mesh>
              );
            })}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default Bridge;
