import React, { useEffect } from "react";
import { Typography, Button, Box as MuiBox, Paper } from "@mui/material";
import { UserState } from "../../../domain/types/User";
import EventForm from "../../common/EventForm";
import useHomePageBody from "../../hooks/useHomePageBody";
import ScrollToTop from "../../common/ScrollToTop";

type bodyProps = {
  userState: UserState;
  isMaintenance: boolean;
};

const Body = (props: bodyProps) => {
  const userState = props.userState;
  const user = userState.user;
  const isMaintenance = props.isMaintenance;

  const {
    createEvent,
    eventTitle,
    setEventTitle,
    bridgeNumRangeMin,
    setBridgeNumRangeMin,
    bridgeNumRangeMax,
    setBridgeNumRangeMax,
    playerLabelWithErrors,
    setPlayerLabelWithErrors,
    playerOthersLabelWithError,
    setPlayerOthersLabelWithError,

    // maxJoinedUsersNum,
    // setMaxJoinedUsersNum,
  } = useHomePageBody(user);

  useEffect(() => {
    // scriptを読み込み
    const script = document.createElement("script");
    script.src = "https://platform.twitter.com/widgets.js";
    document.body.appendChild(script);
    // アンマウント時に一応scriptタグを消しておく
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <ScrollToTop />
      <Paper>
        <EventForm
          setEventTitle={setEventTitle}
          setBridgeNumRangeMin={setBridgeNumRangeMin}
          setBridgeNumRangeMax={setBridgeNumRangeMax}
          setPlayerLabelWithErrors={setPlayerLabelWithErrors}
          eventTitle={eventTitle}
          bridgeNumRangeMin={bridgeNumRangeMin}
          bridgeNumRangeMax={bridgeNumRangeMax}
          playerLabelWithErrors={playerLabelWithErrors}
          playerOthersLabelWithError={playerOthersLabelWithError}
          setPlayerOthersLabelWithError={setPlayerOthersLabelWithError}
          // maxJoinedUsersNum={maxJoinedUsersNum}
          // setMaxJoinedUsersNum={setMaxJoinedUsersNum}
        />

        <MuiBox m={2} component="div" />

        <MuiBox
          component="div"
          sx={{
            filter: !isMaintenance ? "none" : "grayscale(100%) opacity(0.5)",
            pointerEvents: !isMaintenance ? "auto" : "none",
          }}
          width="100%"
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => createEvent()}
            fullWidth
          >
            あみだくじをつくる
          </Button>
        </MuiBox>

        {isMaintenance && (
          <>
            <MuiBox m={2} component="div">
              <Typography variant="body1">現在メンテナンス中です。</Typography>
            </MuiBox>
          </>
        )}
      </Paper>

      <MuiBox m={3} component="div" />

      <Paper>
        <Typography variant="h1">あみださん</Typography>
        <Typography variant="body1">
          「あみださん」は参加型のあみだくじサイトで、参加者が自ら棒をつないで抽選を行います。
          <br />
          あみださんを使って、みんなで楽しく抽選・役割り分担をしましょう！
        </Typography>
        <img
          src="/images/amidasan-i.png"
          alt="あみださん"
          style={{ width: "100%" }}
        />

        <Typography variant="h1">特徴</Typography>
        <Typography variant="body1">
          ・ネットで簡単にあみだくじ抽選ができる
          <br />
          ・参加者が自ら抽選に参加
          <br />
          ・最大499人まで参加可能
          <br />
          ・公平な抽選が可能
        </Typography>

        <MuiBox m={3} component="div" />

        <Typography variant="h1">はじめかた</Typography>
        <Typography variant="body1">
          1. タイトルを入力
          <br />
          2.「振り分け名」を追加
          <br />
          3. 「その他すべて」の振り分けを設定
          <br />
          4. あみだの複雑度を設定
          <br />
          5. 「あみだくじをつくる」ボタンを押す
        </Typography>

        <MuiBox m={3} component="div" />

        <Typography variant="h1">他メディア</Typography>

        <blockquote className="twitter-tweet">
          <p lang="ja" dir="ltr">
            「あみださん」の使い方を公開しました！🐝<br></br>{" "}
            わずか3分で設定から結果確認までできます。
            これから忘年会やクリスマス会で係分担する機会も増えてくると思いますので、ぜひ使ってみてください〜🍯
            <a href="https://t.co/SOsVITFimF">https://t.co/SOsVITFimF</a>
          </p>
          &mdash; あみださん ( amida-san.com ) (@amida_san_com){" "}
          <a href="https://twitter.com/amida_san_com/status/1856268309106438519?ref_src=twsrc%5Etfw">
            November 12, 2024
          </a>
        </blockquote>

        <MuiBox m={3} component="div" />

        <Button
          variant="contained"
          color="secondary"
          fullWidth
          onClick={() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        >
          上に戻る
        </Button>
      </Paper>
    </>
  );
};

export default Body;
