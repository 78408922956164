import React, { useEffect, useState } from "react";
import { Player } from "../../../../domain/types/Player";
import EachPlayer, { EachPlayerJsonObj } from "./EachPlayer";
import Bridge, { BridgeJsonObj } from "./Bridge";
import Env, { EnvJsonObj } from "./Env";
import Ball, { BallJsonObj } from "./Ball";

type Props = {
  json: string;
  playersSortedByStartIndex: Player[];
  myPlayerIndex?: number;
  playerLabels: string[];
  isRunBall: boolean;
};

const AmidaViewer = (props: Props) => {
  const { json, playersSortedByStartIndex, myPlayerIndex, playerLabels, isRunBall } =
    props;

  const [jsonObj, setJsonObj] = useState<AmidaViewerJsonObj | null>(null);

  useEffect(() => {
    const jsonObj = JSON.parse(json);
    setJsonObj(jsonObj);
  }, [json]);

  return (
    <>
      <Env jsonObj={jsonObj?.env} />
      <EachPlayer
        jsonObj={jsonObj?.eachPlayer}
        playersSortedByStartIndex={playersSortedByStartIndex}
        myPlayerIndex={myPlayerIndex}
        playerLabels={playerLabels}
      />
      <Bridge
        jsonObj={jsonObj?.bridge}
        playersSortedByStartIndex={playersSortedByStartIndex}
        myPlayerIndex={myPlayerIndex}
        playerLabels={playerLabels}
      />
      <Ball
        jsonObj={jsonObj?.ball}
        playersSortedByStartIndex={playersSortedByStartIndex}
        myPlayerIndex={myPlayerIndex}
        playerLabels={playerLabels}
        isRunBall={isRunBall}
      />
    </>
  );
};

interface AmidaViewerJsonObj {
  env: EnvJsonObj;
  eachPlayer: EachPlayerJsonObj;
  bridge: BridgeJsonObj;
  ball: BallJsonObj;
}

export default AmidaViewer;
