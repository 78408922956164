import { FetchStatus } from "./FetchStatus";
import { Player } from "./Player";
import { User } from "./User";

export interface AmidaEventState {
    amidaEvent: AmidaEvent;
    timestamp: string;
    status: FetchStatus;
    isEditing: boolean;
}

export interface AmidaEvent {
    id: string | undefined;
    createdAt: string | undefined;
    updatedAt: string | undefined;
    title: EventTitle | undefined;
    rank: number | undefined;
    status: AmidaEventStatus | undefined
    startTime: string | undefined;
    endTime: string | undefined;
    bridgeNumRange: BridgeNumRange | undefined;
    adminUsers: User[];
    joinedUsers: User[];
    players: Player[];
    playerLabels: PlayerLabel[];
    playerOthersLabel: string | undefined;
    maxJoinedUsersNum: number | undefined;
    isBlockEndWithoutAdmin: boolean
    isBlockUpdateWithoutAdmin: boolean
    lockUpdateEnd: LockUpdateEnd | undefined;
}

export interface EventTitle {
    val: string;
}

export enum AmidaEventStatus {
    WAITING = "waiting",
    OPEN = "open",
    CLOSED = "closed",
}

export interface BridgeNumRange {
    min: number;
    max: number;
}

export interface PlayerLabel {
    eventUuid: string;
    labelName: string;
}

export interface LockUpdateEnd {
    isLock: boolean;
}

