import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { User } from "../../domain/types/User";
import { createUser, getMyUser } from "../../domain/features/UserSlice";
import {
    endEvent,
    joinEvent,
    leaveEvent,
    updateJoinedUser,
} from "../../domain/features/EventSlice";
import { AmidaEvent } from "../../domain/types/Event";
import { Bridge, Direction } from "../../domain/types/Bridge";
import { toast } from "react-toastify";

const useEventPageSuccessOpen = (amidaEvent: AmidaEvent, user: User) => {
    const dispatch = useDispatch();

    const [isAdmin, setIsAdmin] = useState(false);
    useEffect(() => {
        if (user.id) {
            setIsAdmin(amidaEvent.adminUsers.map((u) => u.id).includes(user.id));
        }
    }, [user, amidaEvent]);

    const [isJoined, setIsJoined] = useState(false);
    useEffect(() => {
        if (user.id) {
            setIsJoined(amidaEvent.joinedUsers.map((u) => u.id).includes(user.id));
        }
    }, [user, amidaEvent]);

    const [displayName, setDisplayName] = useState("");
    const [myBridges, setMyBridges] = useState<Bridge[]>([]);

    useEffect(() => {
        if (user.id === undefined) {
            return;
        }
        const myPlayer = amidaEvent.players.find((p) => p.userId === user.id);
        if (myPlayer === undefined) {
            return;
        }

        const displayName = myPlayer.displayName;
        if (displayName === undefined) {
            return;
        }
        setDisplayName(displayName.val);

        const bridges = myPlayer.bridges;
        setMyBridges(bridges);
    }, [amidaEvent.players, user.id]);



    const [selectedMyBridgeIndex, setSelectedMyBridgeIndex] = useState<
        number | null
    >(null);


    const [inputPassword, setInputPassword] = useState("");


    const handleJoinEvent = async () => {
        if (!displayName) {
            alert("名前を入力してください");
            return;
        }

        if (displayName.length > 20) {
            alert("名前を短くしてください");
            return;
        }

        if (amidaEvent.bridgeNumRange === undefined) {
            return;
        }

        if (myBridges.length < amidaEvent.bridgeNumRange.min) {
            alert("横棒を追加してください");
            return;
        }

        if (myBridges.length > amidaEvent.bridgeNumRange.max) {
            alert("横棒を少なくしてください");
            return;
        }

        let isExitRight = false;
        let isExitLeft = false;
        myBridges.forEach((bridge) => {
            if (bridge.direction === Direction.Right) {
                isExitRight = true;
            } else {
                isExitLeft = true;
            }
        });
        if (!(isExitRight && isExitLeft)) {
            alert("左右の両方に横棒を追加してください");
            return;
        }

        if (amidaEvent.maxJoinedUsersNum === amidaEvent.joinedUsers.length) {
            alert("最大参加人数に達しているため参加できません");
            return;
        }

        const eventId = amidaEvent.id;
        if (!eventId) {
            return;
        }

        //参加ユーザの取得
        try {
            var userId = user.id;
            if (!userId) {
                const no_name = "ななしさん";
                const res = await dispatch(createUser(no_name) as any);
                if (!res.payload) {
                    throw new Error();
                }
                const resGetMyUser = await dispatch(getMyUser() as any);
                const resUser: User = resGetMyUser.payload.user;
                if (!resUser.id) {
                    throw new Error();
                }
                userId = resUser.id;
            }

            if (!userId) {
                throw new Error();
            }
        } catch (e) {
            alert("ユーザー情報の取得に失敗しました");
            return;
        }

        try {
            // 参加処理
            const resEvent = await dispatch(
                joinEvent({
                    eventId: eventId,
                    userId: userId,
                    displayName: displayName,
                    bridges: myBridges,
                }) as any
            );
            if (!resEvent.payload.event.id) {
                throw new Error();
            }
        } catch (e) {
            alert("参加に失敗しました");
            return;
        }

        setSelectedMyBridgeIndex(null);
        setIsTrigerdUpdateMyBrige(true);
        toast.success("参加が完了しました！");
    };

    const handleUpdateJoinedUser = async () => {
        if (!displayName) {
            alert("名前を入力してください");
            return;
        }

        if (displayName.length > 20) {
            alert("名前が長すぎます");
            return;
        }


        if (amidaEvent.bridgeNumRange === undefined) {
            return;
        }

        if (myBridges.length < amidaEvent.bridgeNumRange.min) {
            alert("横棒を追加してください");
            return;
        }

        if (myBridges.length > amidaEvent.bridgeNumRange.max) {
            alert("横棒を少なくしてください");
            return;
        }

        let isExitRight = false;
        let isExitLeft = false;
        myBridges.forEach((bridge) => {
            if (bridge.direction === Direction.Right) {
                isExitRight = true;
            } else {
                isExitLeft = true;
            }
        });
        if (!(isExitRight && isExitLeft)) {
            alert("左右の両方に横棒を追加してください");
            return;
        }

        const eventId = amidaEvent.id;
        if (!eventId) {
            return;
        }
        const userId = user.id;
        if (!userId) {
            return;
        }

        try {
            // 更新処理
            const resEvent = await dispatch(
                updateJoinedUser({
                    eventId: eventId,
                    userId: userId,
                    displayName: displayName,
                    bridges: myBridges,
                }) as any
            );
            if (!resEvent.payload.event.id) {
                throw new Error();
            }
        } catch (e) {
            alert("更新に失敗しました");
            return;
        }

        setSelectedMyBridgeIndex(null);
        setIsTrigerdUpdateMyBrige(true);
        toast.success("更新が完了しました！");
    };

    const handleLeaveEvent = async () => {
        const eventId = amidaEvent.id;
        if (!eventId) {
            return;
        }
        const userId = user.id;
        if (!userId) {
            return;
        }

        try {
            // 退出処理
            const resEvent = await dispatch(
                leaveEvent({ eventId: eventId, userId: userId }) as any
            );
            if (!resEvent.payload.event.id) {
                throw new Error();
            }
        } catch (e) {
            alert("退出に失敗しました");
        }

        setSelectedMyBridgeIndex(null);
        setIsTrigerdUpdateMyBrige(true);
        toast.success("退出が完了しました！");
    };

    const handleEndEvent = async () => {
        const eventId = amidaEvent.id;
        if (!eventId) {
            return;
        }

        const adminUserId = user.id;
        if (!adminUserId) {
            return;
        }

        try {
            // あみだくじ終了処理
            const resEvent = await dispatch(
                endEvent({
                    eventId: eventId,
                    adminUserId: adminUserId,
                    password: inputPassword,
                }) as any
            );
            if (!resEvent.payload.event.id) {
                throw new Error();
            }
        } catch (e) {
            alert("あみだくじ開始に失敗しました");
        }
        setInputPassword("");
    };

    const [isTriggerdUpdateMyBridges, setIsTrigerdUpdateMyBrige] = useState(false);

    //isTriggerdUpdateMyBridgesが true になって3秒後に false になる
    //ここはアニメーションの時間と合わせている
    useEffect(() => {
        if (!isTriggerdUpdateMyBridges) {
            return;
        }

        const timer = setTimeout(() => {
            setIsTrigerdUpdateMyBrige(false);
        }, 3000);

        return () => clearTimeout(timer);
    }, [isTriggerdUpdateMyBridges]);

    return {
        isAdmin,
        isJoined,
        displayName,
        setDisplayName,
        myBridges,
        setMyBridges,
        selectedMyBridgeIndex,
        setSelectedMyBridgeIndex,
        inputPassword,
        setInputPassword,
        joinEvent: handleJoinEvent,
        updateJoinedUser: handleUpdateJoinedUser,
        leaveEvent: handleLeaveEvent,
        endEvent: handleEndEvent,
        isTriggerdUpdateMyBridges,
    };
};

export default useEventPageSuccessOpen;
