import { Environment } from "@react-three/drei";
import React, { useEffect, useState } from "react";
// import Fireworks from "../Fireworks";

type Props = {
  jsonObj?: EnvJsonObj;
};

export interface EnvJsonObj {
  background: string[];
}

const Env = (props: Props) => {
  const { jsonObj } = props;
  const [background, setBackground] = useState<string[]>();
  useEffect(() => {
    if (jsonObj) setBackground(jsonObj.background);
  }, [jsonObj]);

  return (
    <>
      <Environment files={background} path="/images/" background />
      {/* <Fireworks /> */}
    </>
  );
};

export default Env;
